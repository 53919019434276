* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
